import React from 'react'
import { classNames } from './utilFunctions'

function IconButton(props) {
    const {className = 'border  rounded-full bg-slate-900 mr-2 mt-1'} = props
    return (
        <div className={classNames(className)}>
            <div className=' p-2 '> 
                {props.children}
            </div>
        </div>
    )
}

export default IconButton