import React, { useState, useEffect, useRef } from 'react';

function ChatbotInstance(props) {
    const {showChatbot, setShowChatbot, polygonData, marker } = props
    const [messages, setMessages] = useState([
        { sender: 'AI', text: 'Hi, how can I help you today?' }
    ]);
    const [input, setInput] = useState('');
    const messagesEndRef = useRef(null); // Ref for auto-scrolling

    // Function to auto-scroll to the bottom
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Scroll to bottom whenever messages change
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSendMessage = async () => {
        if (!input.trim()) return;

        const userMessage = { sender: 'You', text: input };
        setMessages(prev => [...prev, userMessage]);

        setInput('');

        try {
            const response = await fetch('https://chat.gamblepower.co.in/chat', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    question: userMessage.text ,
                    point: marker,       
                    polygon: polygonData
                })
            });

            const data = await response.json();
            const aiMessage = { sender: 'AI', text: data.answer || 'Sorry, I couldn’t find an answer.' };

            setMessages(prev => [...prev, aiMessage]);
        } catch (error) {
            setMessages(prev => [...prev, { sender: 'AI', text: 'Error connecting to the server.' }]);
        }

        
    };

    return (
        <div
            style={{ boxShadow: '0 1px 2px rgb(0 0 0 / 0.05)' }}
            className="fixed bottom-[1rem] right-0 mr-4 bg-[#142C3A] p-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[634px] flex flex-col"
        >
            <div className="flex justify-between items-center pb-6 text-white">

            <div className="flex flex-col space-y-1.5  text-white">
                <h2 className="font-semibold text-lg tracking-tight">Chatbot</h2>
                <p className="text-sm text-[#adb5c5] leading-3">Powered by Mendable and Vercel</p>
            </div>
            <button
                            className="text-gray-400 hover:text-white"
                            onClick={() => setShowChatbot(!showChatbot)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>

                        </button>
            </div>

            {/* Chat Messages Container */}
            <div className="pr-4 h-[474px] overflow-y-auto">
                {messages.map((msg, index) => (
                    <div key={index} className="flex gap-3 my-4 text-gray-100 text-sm flex-1">
                        <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                            <div className="rounded-full bg-gray-100 border p-1">
                                {msg.sender === 'AI' ? (
                                    <svg stroke="none" fill="black" stroke-width="1.5"
                                        viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                                        </path>
                                    </svg>
                                ) : (
                                    <svg stroke="none" fill="black" viewBox="0 0 16 16" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                                    </svg>
                                )}
                            </div>
                        </span>
                        <p className="leading-relaxed">
                            <span className="block font-bold text-gray-100">{msg.sender} </span>
                            {msg.text}
                        </p>
                    </div>
                ))}
                {/* Invisible div to track end of chat messages for auto-scroll */}
                <div ref={messagesEndRef}></div>
            </div>

            {/* Message Input */}
            <div className="flex items-center pt-0">
                <form
                    className="flex items-center justify-center w-full space-x-2"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSendMessage();
                    }}
                >
                    <input
                        className="flex h-10 w-full bg-gray-800 rounded-md border border-[#a7acb6] px-3 py-2 text-sm placeholder-[#6b7280] outline-none focus:ring-2 focus:ring-[#0b0b0b] disabled:cursor-not-allowed disabled:opacity-50 text-[#f0f1f4] focus-visible:ring-offset-2"
                        placeholder="Type your message"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button
                        type="submit"
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
                    >
                        Send
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ChatbotInstance;
