import React from 'react'

function WindIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className='size-5'  stroke-width="0.005" stroke="white" viewBox="0 0 16 13.998">
            <path d="M4.9-6H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5-4H4.944a1.044,1.044,0,0,1,1.044.831A1,1,0,0,1,5-2a1.006,1.006,0,0,1-.95-.684A.494.494,0,0,0,3.575-3H2.55a.5.5,0,0,0-.5.575A3.007,3.007,0,0,0,5,0,3,3,0,0,0,7.972-3.394,3.066,3.066,0,0,0,4.9-6ZM.5-7H11a3.006,3.006,0,0,0,2.931-3.647,2.989,2.989,0,0,0-2.284-2.284,3.007,3.007,0,0,0-3.591,2.359A.493.493,0,0,0,8.55-10H9.575a.491.491,0,0,0,.475-.316A1.006,1.006,0,0,1,11-11a1,1,0,0,1,.987,1.169A1.041,1.041,0,0,1,10.944-9H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5-7Zm12,1H7.616A3.95,3.95,0,0,1,8.859-4H12.5A1.5,1.5,0,0,1,14-2.5,1.5,1.5,0,0,1,12.5-1a1.5,1.5,0,0,1-1.3-.763A.517.517,0,0,0,10.747-2H9.691a.5.5,0,0,0-.478.659A3.485,3.485,0,0,0,13.256.919,3.491,3.491,0,0,0,15.9-1.666,3.507,3.507,0,0,0,12.5-6Z"
                transform="translate(0 12.999)"
                fill="#fff"
                
            />
        </svg>
    )
}

export default WindIcon