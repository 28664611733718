import React from 'react';
// import SplitMapButton from './split-map-button';
// import Toggle3dButton from './toggle-3d-button';
// import LayerSelectorPanel from './layer-selector-panel';
// import MapLegendPanel from './map-legend-panel';
import MapDrawPanel from './map-draw-panel';
import DrawIcon from '../../utils/DrawIcon';
// import LocalePanel from './locale-panel';
import IconButton from '../../utils/IconButton';
import SelectModeIcon from '../../utils/SelectModeIcon';
import TrashIcon from '../../utils/TrashIcon';
import CropIcon from '../../utils/CropIcon';
import PollutantDropdown from '../PollutantDropdown';
import WindIcon from '../../utils/WindIcon';

const DEFAULT_ACTIONS = [MapDrawPanel];

const MapControl = (props) => {
  const { showChatbot, setShowChatbot, drawRef, showWind, setShowWind, handleDrawDelete, selectedPollutant, setSelectedPollutant, polygonMode, setPolygonMode, startDrawMode, setStartDrawMode } = props;



  const toggleDrawPolygon = () => {

    if (drawRef.current) {
      if (polygonMode) {
        setPolygonMode(false)
        drawRef.current.deleteAll();
      }
      const currentMode = drawRef.current.getMode();
      const newMode = currentMode === 'draw_polygon' ? 'simple_select' : 'draw_polygon';
      drawRef.current.changeMode(newMode);
      if (newMode === 'draw_polygon') {
        setStartDrawMode(true);
      }

    }
  };

  const deleteAll = () => {
    handleDrawDelete()
  };

  const selectMode = () => {
    if (drawRef.current) {
      drawRef.current.changeMode('simple_select');
    }
  };

  const toggleWindAnimation = () =>{
    setShowWind(!showWind);
  }

  const togglechat = () =>{
    setShowChatbot(!showChatbot);
  }





  return (
    <div className="map-control flex flex-col justify-center items-center mr-5 gap-2" style={{ position: 'fixed', right: 0, marginTop: 25, zIndex: 10 }}>
      <div className='cursor-pointer rounded-full h-11 w-11 transition transform duration-300 hover:scale-110' onClick={toggleDrawPolygon}>
        <IconButton startDrawMode={startDrawMode}>
          <CropIcon startDrawMode={startDrawMode} />
        </IconButton>
      </div>
      <div className='cursor-pointer rounded-full h-11 w-11 transition transform duration-300 hover:scale-110' onClick={deleteAll} >
        <IconButton >
          <TrashIcon />
        </IconButton>
      </div>

      <div className='cursor-pointer rounded-full h-11 w-11 transition transform duration-300 hover:scale-110 flex justify-center items-start' onClick={toggleWindAnimation} >
        <IconButton >
          <WindIcon />
        </IconButton>
      </div>


      <div className=' cursor-pointer rounded-full h-11 w-11 transition transform duration-300 hover:scale-110   flex justify-center items-start ' onClick={togglechat} >
      {/* <div class="rounded-full bg-gray-900 border  pt-1 flex justify-center items-start   "> */}
      <IconButton className='border  rounded-full bg-gradient-to-r from-blue-500 to-purple-500 mr-2 mt-1' >
        <svg stroke="none" fill="white" stroke-width="1.5"
                            viewBox="0 0 24 24" aria-hidden="true" className='size-5' xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                            </path>
          </svg>
          </IconButton>
                        {/* </div> */}
      </div>



      <div className='  rounded-full  transition transform duration-300 hover:scale-110 cursor-pointer w-14'>
        <PollutantDropdown setSelectedPollutant={setSelectedPollutant} selectedPollutant={selectedPollutant} />
      </div>


      <div className=' cursor-pointer' onClick={selectMode}>
        {/* <IconButton>
        <DrawIcon />
        </IconButton>  */}
      </div>
      {/* {actionComponents.map((ActionComponent, index) => {
      
          return   <ActionComponent key={index} buttonClick={onClick}  />
        // return <div>gii</div>
      }
      )} */}
    </div>
  );
};

export default MapControl;