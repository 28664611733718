import React from 'react'
import ChatbotInstance from './ChatbotInstance'

function Chatbot(props) {
  const { showChatbot, setShowChatbot, polygonData, marker, polygonMode } = props
  return (
    <div>
      <ChatbotInstance
        showChatbot={showChatbot}
        setShowChatbot={setShowChatbot}
        polygonData={polygonMode && polygonData ?polygonData : null}
        marker={marker}
      />
    </div>
  )
}

export default Chatbot
