import React from 'react';
import ButtomPanel from './ButtomPanel';
import MapControl from './Map-controls/MapControl';
import MapContainer from './MapContainer';
import Indicators from './Map-controls/Indicators';
import Chatbot from './chatbot';

function MainLayout(props) {
  const { showChatbot,  polygonData, setShowChatbot, marker, setMarker, mapref, showWind, setShowWind, selectedPollutant, setSelectedPollutant, time, setTime, vehicalPolygonAverage, initialPollutionData, aggregateVehicleData, setStartDrawMode, startDrawMode, polygonMode, setPolygonMode, handleDrawDelete, drawRef, map, setMap, draw, setDraw } = props;

  return (
    <div className='h-full relative'>
      <MapControl
        showWind={showWind}
        showChatbot={showChatbot}
        setShowChatbot={setShowChatbot}
        setShowWind={setShowWind}
        setStartDrawMode={setStartDrawMode}
        selectedPollutant={selectedPollutant}
        setSelectedPollutant={setSelectedPollutant}
        startDrawMode={startDrawMode}
        polygonMode={polygonMode}
        setPolygonMode={setPolygonMode}
        handleDrawDelete={handleDrawDelete}
        drawRef={drawRef}
      />
      
      <Indicators />

      <MapContainer
        time={time}
        mapref={mapref}
        setTime={setTime}
        marker={marker}
        setMarker={setMarker}
        selectedPollutant={selectedPollutant}
        polygonMode={polygonMode}
        setPolygonMode={setPolygonMode}
        startDrawMode={startDrawMode}
        setStartDrawMode={setStartDrawMode}
        drawRef={drawRef}
        map={map}
        setMap={setMap}
        draw={draw}
        setDraw={setDraw}
        showWind={showWind}
        setShowWind={setShowWind}
      />

      {/* Animate Chatbot using Tailwind */}
      {showChatbot && (
        <div
          className={`fixed select-none bottom-4 right-4 transition-all duration-[5s] ease-in-out transform ${
            showChatbot ? 'scale-100 opacity-100 translate-y-0' : 'scale-10 opacity-0 translate-y-5'
          }`}
        >
          <Chatbot showChatbot={showChatbot} setShowChatbot={setShowChatbot} polygonData={polygonData} marker={marker} polygonMode={polygonMode} />
        </div>
      )}
    </div>
  );
}

export default MainLayout;
